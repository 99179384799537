import { cx } from '@emotion/css';
import { AddRounded } from '@mui/icons-material';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isNonEmptyString } from '@sortlist-frontend/utils';
import { FC, Fragment, ReactElement, ReactNode } from 'react';

import { ExistingQuestion } from '_components/Briefing/types';
import { Skeleton } from '_components/common/Skeleton';

import * as S from './styles';

type TitleProps = {
  title?: string | ReactElement;
  titleClass?: string;
  subtitle?: string | ReactElement;
  isMandatory?: boolean;
};

type Props = {
  children: ReactNode;
  answer?: ReactNode | string;
  isLoading?: boolean;
  name?: ExistingQuestion | undefined;
  editing?: boolean;
  customId?: string;
  edit?: () => void;
  cancelEdit?: () => void;
};

type QuestionStructureProps = Props & TitleProps;

export const Titles = (props: TitleProps) => {
  const { title, titleClass, subtitle, isMandatory } = props;
  const { t } = useTranslation(['briefing']);

  return (
    <Fragment>
      <div className="layout-row layout-align-start-start">
        {title && <div className={cx(titleClass)}>{title}</div>}
        {isMandatory && <span className="small text-danger-500 ml-4">{`(${t('briefing:required')})`}</span>}
      </div>
      {isNonEmptyString(subtitle) && <p className="mt-8 mb-32 small text-secondary-500">{subtitle}</p>}
    </Fragment>
  );
};

export const QuestionStructure: FC<QuestionStructureProps> = (props) => {
  const { children, answer, isLoading = false, name, editing = true, edit, cancelEdit, isMandatory, customId } = props;

  const { t } = useTranslation(['briefing']);

  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
          <Titles {...props} isMandatory={isMandatory} />
          <Skeleton variant="text" width={200} />
        </Fragment>
      ) : editing ? (
        <div className="layout-column" id={customId ?? `${name}-question-answer`}>
          <div className="layout-row layout-align-start-start">
            <div className="flex">
              <Titles {...props} isMandatory={isMandatory} />
            </div>

            {cancelEdit && (
              <Button
                size="xs"
                type="button"
                className="ml-16"
                buttonStyle="secondary"
                buttonVariant="outlined"
                label={t('briefing:cancel')}
                animation="ripple"
                onClick={cancelEdit}
                id={`cancel-edit-btn-${name}`}
              />
            )}
          </div>
          <div className={cx(cancelEdit && 'width-gt-xs-80', 'width-100')}>{children}</div>
        </div>
      ) : (
        <div className="layout-row layout-align-start-center">
          <div className="flex">
            <Titles {...props} isMandatory={isMandatory} />
            <S.DisplayText className="text-break-word">{answer || '-'}</S.DisplayText>
          </div>

          {edit && (
            <Button
              size="xs"
              type="button"
              className="ml-16"
              buttonStyle={!!answer ? 'secondary' : 'primary'}
              buttonVariant={!!answer ? 'outlined' : 'raised'}
              {...(!answer && { iconRight: <AddRounded /> })}
              label={!!answer ? t('briefing:edit') : t('briefing:add')}
              animation="ripple"
              onClick={edit}
              id={!!answer ? `briefing-edit-btn-${name}` : `briefing-add-btn-${name}`}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};
