import { cx } from '@emotion/css';
import { InfoTwoTone, WarningTwoTone } from '@mui/icons-material';
import { ChangeEvent, ComponentPropsWithoutRef, forwardRef, ReactElement, useState } from 'react';

import { Tooltip } from '../../Tooltip';

export type TextareaProps = {
  className?: string;
  error?: boolean;
  errorIcon?: boolean;
  errorMessage?: string;
  icon?: ReactElement;
  label?: string;
  tip?: boolean;
  tipIcon?: boolean;
  tipMessage?: string;
  resize?: 'horizontal' | 'vertical' | 'both';
} & Partial<ComponentPropsWithoutRef<'textarea'>>;

const defaultProps = {
  cols: 50,
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {
    name,
    icon,
    resize,
    error,
    label,
    tipIcon,
    errorIcon,
    className,
    tipMessage,
    errorMessage,
    onChange,
    ...restProps
  } = { ...defaultProps, ...props };

  const errorClass = cx({ 'form-error': error || errorIcon });
  const tipClassName = cx({ 'pr-16': !!restProps.maxLength });
  const showTipIcon = !error && !errorIcon && tipMessage && tipIcon;
  const showErrorIcon = error && errorIcon && errorMessage && !restProps.disabled;
  const errorIconClass = cx({ 'pr-32': !!showErrorIcon });
  const showErrorMessage = error && !errorIcon && errorMessage && !restProps.disabled;

  const [counter, setCounter] = useState((restProps.defaultValue as string)?.length || 0);
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCounter(e.currentTarget.value.length);
    onChange && onChange(e);
  };

  return (
    <div className={cx(`${className} display-inline-block width-100`)}>
      {label && (
        <label className="medium small mb-16 display-block" htmlFor={restProps.id}>
          {label}
        </label>
      )}

      <div className="field-container layout-row layout-align-start-start">
        {icon && <div className="mr-8 mt-8">{icon}</div>}

        <div className="layout-column flex">
          <textarea
            {...restProps}
            onChange={handleChange}
            className={`width-100 ${errorClass} textarea-resize-${resize} ${errorIconClass}`}
            name={name}
            ref={ref}
          />
          {(error || restProps.maxLength || tipMessage) && (
            <div className="layout-row layout-align-start-start pt-4">
              <div className={`flex layout-row layout-align-start-start ${tipClassName}`}>
                {showErrorMessage && <p className="small text-error">{errorMessage}</p>}
                {tipMessage && !error && <p className="small">{tipMessage}</p>}
              </div>
              {restProps.maxLength && (
                <div>
                  <small>
                    {counter}/{restProps.maxLength}
                  </small>
                </div>
              )}
            </div>
          )}
        </div>

        {showErrorIcon && errorMessage && (
          <div className="field-icon-right layout p-8">
            <Tooltip error arrow title={errorMessage}>
              <WarningTwoTone style={{ fontSize: 16 }} className="text-danger-500" />
            </Tooltip>
          </div>
        )}

        {showTipIcon && tipMessage && (
          <div className="field-icon-right layout">
            <Tooltip arrow title={tipMessage}>
              <InfoTwoTone style={{ fontSize: 16 }} className="text-secondary-900" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
});
