import { FormControl, Input, Radio, Textarea } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { isString } from '@sortlist-frontend/utils';
import { BaseSyntheticEvent, Fragment } from 'react';

import type { FreeTextOption } from '_types/generic';

const OTHER_TAG = 'other';

type OtherTypes = 'textarea' | 'inputText';

type Props = {
  name: string;
  choices: FreeTextOption[];
  picked: FreeTextOption;
  onPick: (picked: FreeTextOption) => void;
  otherType?: OtherTypes;
  otherPlaceHolder?: string;
};

export const RadioPicker = (props: Props) => {
  const { t } = useTranslation(['briefing']);
  const { name, choices, picked, onPick, otherType, otherPlaceHolder = t('briefing:otherPlaceholder') } = props;

  const otherElement = () => {
    const pickedValue = isString(picked?.value) ? picked.value : '';

    switch (otherType) {
      // Could be 'inputNumber', 'inputText', 'datePicker', etc. but we'll add them only
      // if and when they will be necessary.

      case 'textarea':
        return (
          <Textarea
            placeholder={otherPlaceHolder}
            rows={1} // looks like an input but is expendable
            name={OTHER_TAG}
            onChange={(e: BaseSyntheticEvent) => {
              onPick({ tag: OTHER_TAG, value: e.target.value });
            }}
            value={picked && picked.tag === OTHER_TAG ? pickedValue : ''}
            resize="vertical"
          />
        );
      case 'inputText':
        return (
          <Input
            placeholder={otherPlaceHolder}
            name={OTHER_TAG}
            onChange={(e: BaseSyntheticEvent) => {
              onPick({ tag: OTHER_TAG, value: e.target.value });
            }}
            value={picked && picked.tag === OTHER_TAG ? pickedValue : ''}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {choices.map(({ tag, value }) => {
        return (
          <FormControl
            key={tag}
            labelClassName="py-8 flex cursor-pointer"
            control={
              <Radio
                id={`${name}-${tag}`}
                name={name}
                checked={tag === picked?.tag}
                value={tag}
                onChange={() => onPick({ tag: tag, value: '' })}
              />
            }
            label={tag === OTHER_TAG ? otherElement() : value}
          />
        );
      })}
    </Fragment>
  );
};
